<!--
 * @Desc: 描述
 * @Date: 2020-01-09 01:06:48
 * @LastEditTime: 2024-04-01 23:54:26
 -->
<template>
  <div class="add-header">
    <div class="header-item">{{systemName}}</div>
    <div class="header-item" v-if="$store.state.user.username && $store.state.user.authority === 0">
      <el-button type="text" @click="backHome()">首页</el-button>
    </div>
    <div class="header-item" v-if="$store.state.user.username && $store.state.user.authority === 0">
      <el-button type="text" @click="$router.push('/notice')">查看比赛通知</el-button>
    </div>
    <div class="header-item" v-if="$store.state.user.username && $store.state.user.authority === 0">
      <a
        class="el-button el-button--text item"
        download="自愿参赛责任书.pdf"
        :href="responsibility"
      >下载责任书模板</a>
    </div>
    <div class="header-item" v-if="$store.state.user.username == undefined">
      <el-button type="text" @click="backHome()">首页</el-button>
    </div>
    <div class="header-item" v-if="$store.state.user.username == undefined">
      <el-button type="text" @click="$router.push('/notice')">查看比赛通知</el-button>
    </div>
    <div
      class="username"
      v-if="$store.state.user.username"
    >
    <!-- {{ $store.state.user.username }} -->
      <span title="个人中心" class="personBtn" @click="$router.push('/personal-center')">
        <i class="el-icon-user"></i>
      </span>
      <span
        @click="logout"
        class="logout"
        style="font-weight: normal; margin-left: 15px; cursor: pointer;"
      >退出</span>
    </div>
    <div class="matchTime" v-if="$route.name === 'signup' || $route.name === 'Signin' || $route.name  === 'modifyPass'">
      报名时间：{{ matchTime[0] }} ~ {{ matchTime[1] }}
      <span>{{ timeName }}</span>
      <!-- <span v-bind:style="timeNameColor">{{ timeName }}</span> -->
    </div>
  </div>
</template>

<script>
import { getMatchTime } from '@/api'
import { getFullTimeNow } from '@/utils/utils'

export default {
  components: {},
  props: {},
  data () {
    return {
      systemName: this.$store.state.project.systemName,
      username: '',
      responsibility: './static/notice/assets/file/自愿参赛责任书.pdf',
      // 比赛时间
      matchTime: '',
      // 报名状态
      timeName: '',
      // 定时器
      homeSetInterval: 0
    };
  },
  computed: {},
  watch: {},
  created () {
    this.getMatchTime()
  },
  mounted () { },
  methods: {
    showCommonInfo () {
      this.$router.push('/commonInfo');
    },
    backHome () {
      if (this.$store.state.user.authority === 0) {
        if (this.$route.fullPath === '/home/basic-information') {return}
        this.$router.push('/home/basic-information');
      } else {
        if (this.$route.fullPath === '/admin/index') {return}
        this.$router.push('/admin/index');
      }
    },
    logout () {
      this.$store.dispatch('LogOut').then((res) => {
        this.$router.push('/signin')
      }).catch(error => {
        this.$message.error(error);
      })
    },
    getMatchTime () {
      getMatchTime().then(res => {
        this.matchTime = res.data
        this.$store.commit('SET_MATCHTIME', res.data)
        let now = getFullTimeNow()
        if (now < this.matchTime[0]) {
          this.timeName = '未开始报名'
        } else if (now > this.matchTime[0] && now < this.matchTime[1]) {
          this.timeName = '报名中'
        } else {
          this.timeName = '报名时间已过'
        }
      }).catch(err => {
        console.error(err)
      })
    }
  }
};
</script>

<style scoped lang="scss">
.el-button--text {
  color: #dddee4;
}
.add-header {
  position: fixed;
  top: 0;
  height: 65px;
  line-height: 65px;
  width: 100%;
  background: rgb(48, 4, 167);
  font-size: 24px;
  padding: 0 20px;
  font-weight: bold;
  color: #fff;
  display: block;
  align-items: center;
  flex-wrap: nowrap;
}
.header-item {
  margin-right: 15px;
  align-self: stretch;
  float: left;
  .item {
    text-decoration: none;
    color: #dddee4 !important;
  }
}
.username {
  padding: 0 10px;
  font-size: 20px;
  float: right;
  span.logout {
    font-size: 16px;
  }
}
.matchTime {
  padding: 0 10px;
  font-size: 14px;
  float: right;
}

.personBtn:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (max-width: 1085px) {
    .add-header {
      height: 54px;
      line-height: 55px;
    }
    .header-item {
      font-size: 16px;
    }
    .matchTime {
      float: right;
      padding: 0;
      font-size: 12px;
    }
    .username {
      font-size: 15px ;
      span.logout {
        font-size: 14px;
      }
    }

}

@media screen and (max-width: 850px) {
  .add-header {
      height: 80px;
      line-height: 40px;
  }
  .matchTime {
    float: left;
  }
  .username {
    font-size: 14px;
    span.logout {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 430px) {
    .header-item {
      font-size: 14px;
    }
    .el-button {
      font-size: 10px;
    }
    .username {
      font-size: 12px;
      span.logout {
        font-size: 12px;
      }
    }
    .input-box {
      width: 350px;
      margin: 20px;
    }
    .code {
      width: 215px;
    }
}

@media screen and (max-width: 520px) {
    .header-item {
      font-size: 13px;
      margin-right: 8px;
    }
    .el-button {
      font-size: 11px;
    }
    .username {
      font-size: 11px;
      span.logout {
        font-size: 11px;
      }
    }
    .input-box {
      width: 350px;
      margin: 20px;
    }
    .code {
      width: 215px;
    }
    .matchTime {
      font-size: 9px;
    }
}

</style>
